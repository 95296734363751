import { useContext, useState } from 'react'

import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Form from 'Clutch/Atoms/Form'
import Skeleton from 'Clutch/Atoms/Skeleton'

import { usePrpStore } from '../../../contexts/prpContext'
import useProductResultPage from '/hooks/useProductResultPage'

const SortTypeSelect = () => {
  const Navigate = usePrpStore((x) => x.Navigate)
  const sortOrders = useProductResultPage((x) => x.sortOrders)
  const [loading, setIsLoading] = useState(false)

  if (!sortOrders) {
    return <Skeleton height={40} width={250} />
  }

  const links = sortOrders?.map((x) => {
    return { url: x.url, label: x.title }
  })

  const navigate = (e) => {
    const url = links.find((link) => link.label == e.target.value).url
    Navigate(e, url, setIsLoading)
  }

  const getSortTypeOptions = () => {
    const options = sortOrders.map((x) => {
      return { label: x.title, value: x.title, checked: x.isSelected }
    })
    return []
      .concat({ value: '', label: 'Sort by', disabled: true })
      .concat(options)
  }

  const getSelectedSortType = () => {
    return sortOrders.find((x) => x.isSelected).title
  }

  return (
    <CoveringSpinner isLoading={loading} opacityWhenLoading={0.5}>
      <Form.Select
        id={'sortType'}
        options={getSortTypeOptions()}
        label={'Sort By:'}
        value={getSelectedSortType()}
        onChange={navigate}
        inline
      />
    </CoveringSpinner>
  )
}

export default SortTypeSelect
