import React, { useCallback } from 'react'

import Area from '@speedwaymotors/clutch/Molecules/Area'
import { withMemo } from 'Clutch/HigherOrderComponents/withMemo/withMemo'
import ClassNames from 'classnames'
import { useRouter } from 'next/router'

import ColorSwatch from 'Clutch/Atoms/ColorSwatch/ColorSwatch'
import CheckBoxGroup from 'Clutch/Atoms/Form/Elements/Checkbox'
import Rating from 'Clutch/Atoms/Rating'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import engineFitmentFacetTypes from '../../../../constants/engineFitmentFacetTypes'
import { usePrpStore } from '../../../../contexts/prpContext'
import useProductResultPage from '../../../../hooks/useProductResultPage'
import FacetOptionSwitch from '../facetOptionSwitch/facetOptionSwitch'
import styles from './facetOption.module.scss'

const FacetOption = ({
  facetName,
  facetOption,
  isMultiSelect,
  hidden,
  setIsLoading,
  onClick,
  doNotRenderIfHidden,
}) => {
  const breadcrumb = useProductResultPage((x) => x.breadcrumb)
  const isSearchPage = useProductResultPage((x) => x.isSearchPage)
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const SetNumProductsLoading = usePrpStore((x) => x.SetNumProductsLoading)
  const Navigate = usePrpStore((x) => x.Navigate)
  const attribution = useProductResultPage((x) => x.attribution)

  const router = useRouter()

  const isShopPage = !isSearchPage

  const classNames = [styles.option]

  if (hidden) {
    classNames.push(styles.hidden)
  }

  const segmentCategory = (breadcrumb || [])
    .map((crumb) =>
      crumb.displayText.toLowerCase() == 'home' ? '' : crumb.displayText,
    )
    .filter((crumb) => crumb)
    .join(' > ')
  const sortType = router.query.sortType || 'relevance'
  const segmentSortType =
    sortType == 'pricelowhigh' || sortType == 'pricehighlow'
      ? 'price'
      : sortType == 'highestrated'
        ? 'rating'
        : 'relevance'
  const segmentSortValue =
    sortType == 'pricehighlow' || sortType == 'highestrated'
      ? 'desc'
      : sortType == 'pricelowhigh'
        ? 'asc'
        : null
  const segmentFilters = []
  selectedFacetOptions.forEach((facet) =>
    facet.searchResponseFacetOptions.forEach((option) => {
      segmentFilters.push({
        type: facet.displayText,
        value: option.id,
      })
    }),
  )

  segmentFilters.push({
    type: facetName,
    value: facetOption.id,
  })

  const isNewEngineFacet =
    engineFitmentFacetTypes.includes(facetName) && attribution?.is_new_engine
  const facetType = `${isNewEngineFacet ? 'NewEngine_' : ''}${
    isSearchPage ? 'Search' : 'Shop'
  }`

  const segmentEvent =
    facetName && facetOption?.displayValue
      ? {
          event: 'Product List Filtered',
          properties: {
            list_id: isShopPage ? 'shop_prp_results' : 'search_prp_results',
            category: segmentCategory,
            filters: segmentFilters,
            attribution: attribution,
            facet_type: facetType,
            sorts: [{ type: segmentSortType, value: segmentSortValue }],
          },
        }
      : false

  const onClickFacet = useCallback(
    (e) => {
      if (onClick) {
        onClick()
      }

      if (
        !isMultiSelect ||
        !selectedFacetOptions?.some((x) => x.displayText == facetName)
      ) {
        SetNumProductsLoading(facetOption.resultCount)
      }
      Navigate(e, facetOption.facetOptionLink.url, setIsLoading)
    },
    [
      onClick,
      isMultiSelect,
      JSON.stringify(selectedFacetOptions),
      JSON.stringify(facetOption),
    ],
  )

  if (doNotRenderIfHidden && hidden) {
    return false
  }
  if (!facetOption?.facetOptionLink?.url) {
    return false
  }

  return (
    <TextLinkMemo
      segmentEvent={segmentEvent}
      href={
        facetOption?.facetOptionLink?.noFollow
          ? null
          : facetOption?.facetOptionLink?.url
      }
      nofollow={facetOption?.facetOptionLink?.noFollow ?? false}
      tone={'normal'}
      size={1}
      font={'regular'}
      as={'a'}
      data-testid={`facetoption_${facetOption?.displayValue}`}
      className={ClassNames(classNames)}
      onClick={onClickFacet}
    >
      {facetName === 'Ships for Free' || facetName === 'Rebates' ? (
        <div className={styles.switchGroup}>
          <FacetOptionSwitch
            facetName={facetName}
            facetOptionValueId={1}
            displayNameOverride={
              facetName === 'Rebates' ? 'Eligible for Rebates' : null
            }
            noDivider
            brand={'secondarypalette_purple'}
          />
          <Typography
            size={0.75}
            tone={'subtle'}
            className={styles.multiSelectCount}
          >
            {facetOption?.resultCount && `${facetOption.resultCount}`}
          </Typography>
        </div>
      ) : isMultiSelect ? (
        <div className={styles.checkBoxGroup}>
          <CheckBoxGroup
            id={facetName + facetOption?.displayValue}
            brand={'secondarypalette_purple'}
            noTransition={true}
            options={[
              {
                //href: facetOption?.facetOptionLink?.url,
                label:
                  facetName == 'Customer Rating' ? (
                    <Rating
                      ratingValue={parseInt(facetOption?.displayValue[0])}
                      size={1}
                    />
                  ) : (
                    facetOption?.displayValue
                  ),
                segmentEvent: segmentEvent,
                checked: facetOption?.isSelected,
                ariaLabel: 'facet option',
                onChange: () => {},
              },
            ]}
          />

          {facetOption.displayPosition !== null && (
            <Area className={styles.pinned} intent={'positive'}>
              <Typography size={0.75}>Pinned</Typography>
            </Area>
          )}
          <Typography
            size={0.75}
            tone={'subtle'}
            className={styles.multiSelectCount}
          >
            {facetOption?.resultCount && `${facetOption.resultCount}`}
          </Typography>
        </div>
      ) : (
        <div className={styles.single_select}>
          <Typography>{facetOption?.displayValue}</Typography>
          {facetOption.displayPosition !== null && (
            <Area className={styles.pinned} intent={'positive'}>
              <Typography size={0.75}>Pinned</Typography>
            </Area>
          )}
          <Typography size={0.75} tone={'subtle'}>
            {facetOption?.resultCount && `${facetOption.resultCount}`}
          </Typography>
        </div>
      )}
      {facetName == 'Color' ? (
        <ColorSwatch
          style={{ marginLeft: '0.25rem' }}
          customColor={facetOption?.displayValue?.replace(' ', '_')}
          size={'L'}
        />
      ) : null}
    </TextLinkMemo>
  )
}

const TextLinkMemo = withMemo(TextLink)

export default FacetOption
