import { useContext, useEffect, useState } from 'react'

import ClassNames from 'classnames'

import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'

import PanelContext from 'Clutch/Contexts/PanelContext'

import useLogRocket from 'Hooks/useLogRocket'

import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Svg from 'Clutch/Atoms/Svg'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import { usePrpStore } from '../../../contexts/prpContext'
import useProductResultPage from '../../../hooks/useProductResultPage'
import styles from './selectedFacets.module.scss'
import SelectedFacetsSkeleton from './selectedFacetsSkeleton'
import styled from 'styled-components'

const SelectedFacets = ({ location = 'desktop', wrap = false }) => {
  const skeleton = useProductResultPage((x) => x.skeleton)
  const isSearchPage = useProductResultPage((x) => x.isSearchPage)
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )

  const [currentHoveredOption, setCurrentHoveredOption] = useState(null)

  const dividerColor = LayerColorFinder(3)
  const panelContext = useContext(PanelContext)
  const { logRocketTrack } = useLogRocket()

  let layer = panelContext?.layer
  layer = layer || 3

  const [loading, setLoading] = useState(false)
  const color = LayerColorFinder(layer, false, null, null)
  const Navigate = usePrpStore((x) => x.Navigate)

  const baseColor = LayerColorFinder(0)
  const gradientColors = {
    '--b': baseColor,
    '--i': baseColor.slice(0, -2) + '0.0)',
  }

  const onClickFacet = (e, facetOption) => {
    setCurrentHoveredOption(null)
    Navigate(e, facetOption.facetOptionLink?.url, setLoading)
  }

  if (skeleton) {
    return <SelectedFacetsSkeleton />
  }

  if (!selectedFacetOptions?.length) {
    return null
  }

  const shouldSkipFitmentFacet = (facetDisplayText) => {
    return (
      location !== 'desktop' &&
      location !== 'noResultPage' &&
      facetDisplayText === 'Fitment'
    )
  }

  const getClassesForLocation = () => {
    return location !== 'noResultPage' ? styles.hideMobile : ''
  }

  useEffect(() => {
    try {
      const fitmentFilter = selectedFacetOptions?.find(
        (x) => x.displayText == 'Fitment' || x.displayText == 'Race Type',
      )
      if (fitmentFilter) {
        const isRaceType = fitmentFilter.fitmentDetails.raceTypeId

        logRocketTrack(
          `FitmentSelected_${isRaceType ? 'RaceType' : 'Ymm'}_${isSearchPage ? 'Search' : 'Shop'}`,
        )
      }
    } catch (err) {
      console.error('Could not send log rocket fitment event', err)
    }
  }, [selectedFacetOptions])

  return (
    <>
      <div
        className={
          location === 'desktop'
            ? ClassNames(styles.chips, styles.hideMobile)
            : styles.chips
        }
        style={gradientColors}
      >
        <div className={ClassNames(styles.fadeout, styles.wrapperMargin)}>
          <CoveringSpinner
            isLoading={loading}
            className={ClassNames(
              styles.fadeout,
              styles[location],
              wrap ? styles.wrap : styles.nowrap,
            )}
          >
            <StyledChipDivider
              className={ClassNames(
                styles.chip_divider,
                getClassesForLocation(),
              )}
              dividerColor={dividerColor}
            >
              <TextLink
                className={getClassesForLocation()}
                intent={'action'}
                data-testid={'clearAllDesktop'}
                size={0.875}
                font={'bold'}
                href={'/shop/all'}
                onClick={(e) => Navigate(e, '/shop/all', setLoading)}
                nofollow
              >
                Clear All
              </TextLink>
            </StyledChipDivider>
            {selectedFacetOptions?.map((selectedFacet, i) => {
              if (
                selectedFacet.searchResponseFacetOptions.every(
                  (x) => x.hidden === true,
                )
              ) {
                return null
              }

              return (
                <div
                  className={styles.chip_wrapper}
                  key={`selected_facet_${selectedFacet.displayText}_${i}`}
                >
                  <Typography
                    className={ClassNames(
                      styles.chip_label,
                      getClassesForLocation(),
                    )}
                    size={0.75}
                  >
                    {selectedFacet.displayText}
                  </Typography>
                  {selectedFacet.searchResponseFacetOptions?.map(
                    (option, i) => {
                      if (
                        shouldSkipFitmentFacet(selectedFacet?.displayText) ||
                        option.hidden === true
                      ) {
                        return null
                      }

                      return (
                        <StyledChip
                          key={`selected_facet_option_${option.displayValue}_${i}`}
                          href={option.facetOptionLink?.url}
                          onClick={(e) => onClickFacet(e, option)}
                          className={styles.chip}
                          onMouseEnter={() =>
                            setCurrentHoveredOption(
                              option.facetOptionIdentifier,
                            )
                          }
                          bordercolor={color}
                          onMouseLeave={() => setCurrentHoveredOption(null)}
                          nofollow={option.facetOptionLink?.noFollow}
                        >
                          <span
                            className={styles.chip_contents}
                            data-testid={`${selectedFacet.displayText}_${option.displayValue}${location !== 'desktop' ? `_${location}` : ''}`}
                          >
                            <Typography
                              className={styles.chip_value}
                              size={0.75}
                              tone={'contrast'}
                              strikethrough={
                                option.parentFacetIdentifiers?.some(
                                  (x) => x == currentHoveredOption,
                                ) ||
                                currentHoveredOption ==
                                  option.facetOptionIdentifier
                              }
                            >
                              {option.displayValue}
                            </Typography>
                            <Svg icon={'close'} size={0.5}></Svg>
                          </span>
                        </StyledChip>
                      )
                    },
                  )}
                </div>
              )
            })}
          </CoveringSpinner>
        </div>
      </div>
    </>
  )
}

const StyledChipDivider = styled.div`
  border-right-color: ${(props) => props.dividerColor};
`

const StyledChip = styled(TextLink)`
  border-color: ${(props) => `${props.bordercolor}`};
`

export default SelectedFacets
