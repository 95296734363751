import React, {useContext, useEffect, useState} from 'react'

import TopVehiclesGrid from 'Clutch/Containers/TopVehiclesGrid'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import ClassNames from 'classnames'

import {StorageAccessor} from 'Clutch/Utilities/LocalStorageUtility'
import getFitmentDetailsFromSelectedFacets from 'Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'

import {ListContext} from 'Clutch/Contexts/ListContext/ListContext'
import {NavigationContext} from 'Contexts/Navigation/NavigationContext'

import {useFeatureFlag} from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag'

import StickyHeader from 'Clutch/Components/StickyHeader'

import AdditionalFacetDrawer from 'Clutch/Organisms/AdditionalFacetDrawer/additionalFacetDrawer'

import CalloutSection from 'Clutch/Molecules/CalloutSection/CalloutSection'
import {
  dealsCallout,
  raceCallout,
  streetCallout,
  trendingCallout,
} from 'Clutch/Molecules/CalloutSection/calloutConstants'

import Gutters from 'Clutch/Atoms/Gutters'
import Panel from 'Clutch/Atoms/Panel'

import withTracer from '../Utilities/withTracer'
import {PrpListSelectedKey} from '../constants/localStorageKeys'
import AdditionalLinksGroup from '../containers/additionalLinks/additionalLinksGroup'
import AplusHeaderContent from '../containers/aplusContent/aplusHeaderContent'
import Campaign from '../containers/campaign/campaign'
import TopCampaign from '../containers/campaign/topCompaign'
import Categories from '../containers/categories/categories'
import CompareToolModal from '../containers/compareToolModal/compareToolModal'
import Extended from '../containers/extended/extended'
import Facets from '../containers/facets/facets'
import HeadContent from '../containers/head/head'
import JsonLD from '../containers/json-ld/jsonLD'
import Options from '../containers/options/options'
import ProductCarousel from '../containers/productCarousel/productCarousel'
import Products from '../containers/products/products'
import Recommendations from '../containers/recommendations/recommendations'
import ShopAllFacets from '../containers/shopallFacets/shopAllFacets'
import Sidebar from '../containers/sidebar/sidebar'
import SidebarDrawer from '../containers/sidebar/sidebarDrawer'
import StickyHeaderContent from '../containers/stickyHeader/stickyHeader'
import Title from '../containers/title/title'
import VehicleOptions from '../containers/vehicleOptions/vehicleOptions'
import {usePrpStore} from '../contexts/prpContext'
import useProductResultPage from '../hooks/useProductResultPage'
import useVehicleSidebar from '../hooks/useVehicleSidebar'
import Custom404 from '../pages/404'
import BreadcrumbWrapper from './../containers/breadcrumb/breadcrumb'
import frpStyles from './facetResultPage.module.scss'
import styles from './productResultPage.module.scss'

const ProductResultPage = ({isFrp}) => {
    const OpenSidebarDrawer = usePrpStore((x) => x.OpenSidebarDrawer)
    const should404 = useProductResultPage((x) => x.should404)
    const instrumentation = useProductResultPage((x) => x.instrumentation)
    const {state: navigationState} = useContext(NavigationContext)
    const searchResponseFacets = useProductResultPage(
        (x) => x.searchResponseFacets,
    )
    const selectedFacetOptions = useProductResultPage(
        (x) => x.selectedFacetOptions,
    )
    const engineFitmentPageTitle = useProductResultPage(
        (x) => x.engineFitmentPageTitle,
    )
    const fitmentDetails =
        getFitmentDetailsFromSelectedFacets(selectedFacetOptions || []) || {}
    const {vehicleName} = useVehicleSidebar(
        selectedFacetOptions || [],
        fitmentDetails,
        engineFitmentPageTitle,
    )

    useFeatureFlag('PRP_Suggest_Facets_Metrics')

    if (should404) {
        return <Custom404/>
    }

    let BoostingData
    try {
        if (
            instrumentation?.metrics?.boostingCampaigns?.length ||
            instrumentation?.metrics?.productCampaigns?.length ||
            instrumentation?.metrics?.searchProviderRequestJson
        ) {
            BoostingData =
                require('../components/searchProviderBoostingData/searchProviderBoostingData').default
        }
    } catch (err) {
        console.error(err)
    }

    return (
        <>
            <HeadContent
                pageType={isFrp ? 'Facets Result Page' : 'Product Results Page'}
            />
            <JsonLD/>
            <CompareToolModal/>
            <main>
                {!navigationState?.discovery?.isSaytModalOpen &&
                !navigationState?.discovery?.isSearchModalOpen ? (
                    <StickyHeader
                        triggerId={`${isFrp ? 'frp' : 'prp'}_stickyHeaderTrigger`}
                    >
                        <Gutters noVerticalPadding>
                            <StickyHeaderContent
                                openSidebarDrawerFunction={OpenSidebarDrawer}
                                classNames={ClassNames(styles.stickyHeaderPrp)}
                                forPrp
                            />
                        </Gutters>
                    </StickyHeader>
                ) : (
                    false
                )}
                <Panel>
                    <div
                        className={styles.prp_layout_sec}
                        id={`${isFrp ? 'frp' : 'prp'}_breadcrumb`}
                    >
                        <Panel>
                            <BreadcrumbWrapper/>
                        </Panel>
                    </div>
                    <div
                        className={styles.prp_layout_sec}
                        id={`${isFrp ? 'frp' : 'prp'}_aplus_header`}
                    >
                        <AplusHeaderContent/>
                    </div>
                    <Panel>
                        <Gutters noVerticalPadding>
                            <div
                                className={styles.prp_layout_sec}
                                id={`${isFrp ? 'frp' : 'prp'}_title`}
                            >
                                <Title/>
                            </div>
                            {/*TODO: Add buttons below here if you want it above the categories */}
                        </Gutters>
                    </Panel>
                    {isFrp ? (
                        <Gutters noVerticalPadding>
                            <StickyHeaderContent
                                openSidebarDrawerFunction={OpenSidebarDrawer}
                                location={'stickyHeaderTrigger'}
                            />
                            <div id={'frp_stickyHeaderTrigger'}></div>
                        </Gutters>
                    ) : (
                        false
                    )}

                    <div
                        className={isFrp ? frpStyles.frp_layout_sec : styles.prp_layout_sec}
                    >
                        <Gutters>
                            {isFrp ? (
                                <FrpMain/>
                            ) : (
                                <PrpMain openSideBarDrawer={OpenSidebarDrawer}/>
                            )}

                            <ErrorBoundary>
                                <AdditionalLinksGroup propName={'bottomPageLinks'}/>
                            </ErrorBoundary>
                            <Recommendations/>
                            <Extended/>
                        </Gutters>
                    </div>
                </Panel>
            </main>
            <SidebarDrawer/>
            <AdditionalFacetDrawer
                searchResponseFacets={searchResponseFacets}
                selectedFacetOptions={selectedFacetOptions}
                vehicleName={vehicleName}
                engineFitmentPageTitle={engineFitmentPageTitle}
            />
            {BoostingData ? <BoostingData/> : false}
        </>
    )
}

function GetUserListSetter() {
    // rerender just this instead of the whole PRP
    const {getUserLists} = useContext(ListContext)

    useEffect(() => {
        getUserLists(true)
    }, [])
    return false
}

function GoogleRemarketing() {
    const searchProducts = useProductResultPage((x) => x.searchProducts)
    const isSearchPage = useProductResultPage((x) => x.isSearchPage)
    const [remarketingProductIdsSent, setRemarketingProductIdsSent] = useState([])

    useEffect(() => {
        try {
            if (
                typeof window !== 'undefined' &&
                searchProducts?.length &&
                searchProducts?.some((x) => !x.lazyLoad)
            ) {
                const googleItemsToSend = searchProducts?.filter(
                    (x) =>
                        !x.lazyLoad &&
                        !remarketingProductIdsSent.some(
                            (y) => y === x.skuBaseDetails?.skuVariantSummaries[0].skuNumber,
                        ),
                )
                const googleItems = googleItemsToSend.map((x) => {
                    return {
                        id: x.skuBaseDetails?.skuVariantSummaries[0].skuNumber,
                        google_business_vertical: 'retail',
                    }
                })

                setRemarketingProductIdsSent(
                    remarketingProductIdsSent.concat(googleItems.map((x) => x.id)),
                )
                if (googleItems.length) {
                    window.gtag(
                        'event',
                        isSearchPage ? 'view_search_results' : 'view_item_list',
                        {items: googleItems},
                    )
                }
            }
        } catch (err) {
            console.error(err)
        }
    }, [searchProducts?.filter((x) => x.lazyLoad).length, searchProducts?.length])

    return false
}

const PrpMain = ({openSideBarDrawer}) => {
    const [productListView, setProductListView] = useState(true)

    const isNoResultPage = useProductResultPage((x) => x.isNoResultPage)

    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth < 835) {
            setProductListView(true)
            return
        }

        const lastSelectedLayout =
            StorageAccessor.localStorage.getObj(PrpListSelectedKey)

        if (lastSelectedLayout !== undefined && lastSelectedLayout !== null) {
            setProductListView(lastSelectedLayout)
        }
    }, [])

    return (
        <div className={styles.prp_main}>
            <GoogleRemarketing/>
            <GetUserListSetter/>
            {!isNoResultPage && (
                <>
                    <aside className={styles.prp_layout_sidebar} id={'prp_sidebar'}>
                        <Sidebar/>
                    </aside>
                    <div className={styles.prp_layout_spacer}/>
                </>
            )}
            <div
                className={
                    isNoResultPage
                        ? styles.prp_layout_noresults
                        : styles.prp_layout_results
                }
                id={'prp_results'}
            >
                <VehicleOptions/>
                <Categories/>
                <TopCampaign/>
                <StickyHeaderContent
                    openSidebarDrawerFunction={openSideBarDrawer}
                    location={'stickyHeaderTrigger'}
                />
                <div id={'prp_stickyHeaderTrigger'}></div>
                <Options
                    setProductListView={setProductListView}
                    isProductListView={productListView}
                />
                {/*<PartialResult searchQuery={searchQuery}/>*/}
                <Products productListView={productListView}/>
            </div>
        </div>
    )
}

const FrpMain = ({}) => {
    const selectedFacetOptions = useProductResultPage(
        (x) => x.selectedFacetOptions,
    )
    const isShopAllPage = selectedFacetOptions?.length === 0
    const frpProductPlacement = useProductResultPage((x) => x.frpProductPlacement)

    return (
        <div className={frpStyles.frp_main}>
            <aside className={frpStyles.frp_layout_sidebar} id={'frp_sidebar'}>
                <Sidebar/>
            </aside>
            <div className={frpStyles.frp_layout_spacer}/>
            <div className={frpStyles.frp_layout_facets} id={'frp_facets'}>
                <Campaign placement={'top'}/>
                {isShopAllPage ? (
                    <>
                        <CalloutSection
                            leftCallout={streetCallout}
                            rightCallout={raceCallout}
                        />
                        <TopVehiclesGrid audience={'race'}/>
                        <TopVehiclesGrid audience={'street'}/>
                        <CalloutSection
                            leftCallout={trendingCallout}
                            rightCallout={dealsCallout}
                        />
                        <br></br>
                        <Recommendations sectionName={'ShopAll'}/>
                        <ShopAllFacets/>
                    </>
                ) : (
                    <Facets/>
                )}
                {frpProductPlacement === 'bottom' && !isShopAllPage ? (
                    <ProductCarousel numProducts={10}/>
                ) : (
                    false
                )}
            </div>
        </div>
    )
}

export default withTracer(ProductResultPage, 'ProductResultPage')
