import Divider from 'Clutch/Atoms/Divider'
import Html from 'Clutch/Atoms/Html'

import useProductResultPage from '../../hooks/useProductResultPage'
import styles from './extended.module.scss'

const Extended = () => {
  const pageContent = useProductResultPage((x) => x.pageContent)

  return (
    <>
      {pageContent?.html || pageContent?.extendedHtml ? (
        <Divider className={styles.top_divider} />
      ) : null}

      {pageContent?.html ? (
        <div data-testid={'pagecontent_html'}>
          <Html html={pageContent.html} className={styles.html} />
        </div>
      ) : null}

      {pageContent?.html && pageContent?.extendedHtml ? (
        <Divider className={styles.middle_divider} />
      ) : null}

      {pageContent?.extendedHtml ? (
        <div data-testid={'extended_html'}>
          <Html html={pageContent?.extendedHtml} className={styles.html} />
        </div>
      ) : null}

      {pageContent?.html || pageContent?.extendedHtml ? (
        <Divider className={styles.bottom_divider} />
      ) : null}
    </>
  )
}

export default Extended
