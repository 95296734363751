import React, { useState } from 'react'

import Popover from 'Clutch/Components/Popover'

import Area from 'Clutch/Molecules/Area'

import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'

import styles from './diversificationRules.module.scss'

const DiversificationRules = ({
  defaultDiversification,
  facetRuleTargets,
  facetRules,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const hasDefaultDiversification =
    defaultDiversification && defaultDiversification.length > 0
  const hasFacetTargetRuleDiversification =
    facetRuleTargets &&
    facetRuleTargets.some((target) => target.diversification === false)
  const hasFacetRuleDiversification =
    facetRules && facetRules.some((rule) => rule.type === 'Diversification')

  const titleContent =
    hasDefaultDiversification ||
    hasFacetTargetRuleDiversification ||
    hasFacetRuleDiversification
      ? 'Diversification Rules Present'
      : 'No Diversification Rules Applied'

  const disabledTargets = facetRuleTargets?.filter(
    (target) => target.diversification === false,
  )
  const diversificationFacetRules = facetRules?.filter(
    (rule) => rule.type === 'Diversification',
  )

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  const renderDiversificationRules = () =>
    hasDefaultDiversification &&
    !hasFacetTargetRuleDiversification &&
    !hasFacetRuleDiversification && (
      <Area intent="positive" className={styles.ruleGroup}>
        <Typography size={1} className={styles.ruleGroupTitle}>
          <strong>Default Diversification Rules</strong>
        </Typography>
        <ul className={styles.ruleList}>
          {defaultDiversification.map((rule, index) => (
            <li key={index} className={styles.ruleItem}>
              <div className={styles.ruleInfo}>
                <Typography size={0.875} className={styles.ruleName}>
                  {rule.key}
                </Typography>
                <Typography size={0.75} className={styles.ruleCount}>
                  Minimum Distance: {rule.value.minimum_distance}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </Area>
    )

  const renderDisabledTargets = () =>
    disabledTargets?.length > 0 && (
      <Area intent="negative" className={styles.ruleGroup}>
        <Typography size={1} className={styles.ruleGroupTitle}>
          <strong>Disabled Diversification</strong>
        </Typography>
        <ul className={styles.ruleList}>
          {disabledTargets.map((target, index) => (
            <li key={index} className={styles.ruleItem}>
              <div className={styles.ruleInfo}>
                <Typography size={0.875} className={styles.ruleName}>
                  {target.name}
                </Typography>
              </div>
              <a
                href={target.sminetUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.configureLink}
              >
                Configure
              </a>
            </li>
          ))}
        </ul>
      </Area>
    )

  const renderDiversificationFacetRules = () =>
    diversificationFacetRules?.length > 0 && (
      <Area intent="action" className={styles.ruleGroup}>
        <Typography size={1} className={styles.ruleGroupTitle}>
          <strong>Diversification Facet Rules</strong>
        </Typography>
        <ul className={styles.ruleList}>
          {diversificationFacetRules.map((rule) => (
            <li key={rule.id} className={styles.ruleItem}>
              <div className={styles.ruleInfo}>
                <Typography size={0.875} className={styles.ruleName}>
                  {rule.facetTypeDisplayName || rule.facetType}
                  {rule.attributeName && `: ${rule.attributeName}`}
                </Typography>
                <Typography size={0.75} className={styles.ruleCount}>
                  Minimum Distance: {rule.displayPosition + 1}
                </Typography>
              </div>
              <a
                href={rule.sminetUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.configureLink}
              >
                Configure
              </a>
            </li>
          ))}
        </ul>
      </Area>
    )

  const areaContent = (
    <Area
      className={styles.rule}
      title={titleContent}
      intent={
        hasDefaultDiversification ||
        hasFacetTargetRuleDiversification ||
        hasFacetRuleDiversification
          ? 'positive'
          : undefined
      }
    >
      <Typography size={0.75}>{titleContent}</Typography>
    </Area>
  )

  const popoverContent = (
    <div className={styles.popoverContent}>
      {hasDefaultDiversification && renderDiversificationRules()}
      {renderDisabledTargets()}
      {renderDiversificationFacetRules()}
      {!hasDefaultDiversification &&
        !hasFacetTargetRuleDiversification &&
        !hasFacetRuleDiversification && (
          <Typography size={0.875}>
            No diversification rules applied.
          </Typography>
        )}
    </div>
  )

  return (
    <Card noMargin noPadding className={styles.card}>
      <div
        className={styles.rulesWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Popover content={popoverContent} isOpen={isOpen}>
          {areaContent}
        </Popover>
      </div>
    </Card>
  )
}

export default DiversificationRules
