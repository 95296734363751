const buildBreadCrumb = (rawBreadCrumb) => {
  const result = (rawBreadCrumb || []).map((crumb) => {
    return {
      url: crumb.url || crumb.searchResponseFacetOption.facetOptionLink.url,
      displayText: crumb.displayText,
      ordinality: crumb.ordinality,
    }
  })
  return result
}

export default buildBreadCrumb
