import React, { useState } from 'react'

import Popover from 'Clutch/Components/Popover'

import Area from 'Clutch/Molecules/Area'

import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'

import styles from './phrasedQuery.module.scss'

const PhrasedQuery = ({ appliedPhrases }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasPhrases =
    appliedPhrases?.phrases && appliedPhrases.phrases.length > 0
  const titleContent = hasPhrases
    ? 'Phrased Query'
    : 'No Phrased Query Detected'

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  const areaContent = (
    <Area
      className={styles.query}
      title={titleContent}
      intent={hasPhrases ? 'positive' : undefined}
    >
      <Typography size={0.75}>{titleContent}</Typography>
    </Area>
  )

  const popoverContent = hasPhrases ? (
    <div className={styles.popoverContent}>
      <Typography size={1} className={styles.phraseGroupTitle}>
        <strong>Applied Phrases</strong>
      </Typography>
      <ul className={styles.phraseList}>
        {appliedPhrases.phrases.map((phrase) => (
          <li key={phrase.key} className={styles.phraseItem}>
            <div className={styles.phraseInfo}>
              <Typography size={0.875} className={styles.phraseValue}>
                {phrase.value}
              </Typography>
            </div>
            <a
              href={`${appliedPhrases.sminetUrl}${phrase.key}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.configureLink}
            >
              Configure
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className={styles.popoverContent}>
      <Typography size={0.875}>No phrased query detected.</Typography>
      <a
        href={appliedPhrases?.sminetAddNewPhraseUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.addLink}
      >
        Add New Phrase
      </a>
    </div>
  )

  return (
    <Card noMargin noPadding className={styles.card}>
      <div
        className={styles.queryWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Popover content={popoverContent} isOpen={isOpen}>
          {areaContent}
        </Popover>
      </div>
    </Card>
  )
}

export default PhrasedQuery
