import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag'

import Share from 'Clutch/Molecules/Share'

import SideBarBlockWrapper from '../shared/sideBarBlockWrapper'
import useProductResultPage from '/hooks/useProductResultPage'

const ShareButton = ({ dividerTop = false, dividerBottom = false }) => {
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const skeleton = useProductResultPage((x) => x.skeleton)
  const pageTitle = useProductResultPage((x) => x.pageTitle)
  const metaDescription = useProductResultPage((x) => x.metaDescription)

  const shareButton = useFeatureFlag('PRP_FrontEnd_ShareButton')
  if (skeleton || !shareButton) {
    return false
  }
  const facetsToShowFor = ['Promotions', 'Discounts']
  const selectedPromo = selectedFacetOptions.find((x) =>
    facetsToShowFor.some((y) => y === x.displayText),
  )
  if (!selectedPromo) {
    return false
  }
  return (
    <SideBarBlockWrapper dividerTop={dividerTop} dividerBottom={dividerBottom}>
      <Share
        size={0.875}
        title={pageTitle}
        subtitle={metaDescription}
        iconText={`Share These Deals`}
      />
    </SideBarBlockWrapper>
  )
}

export default ShareButton
