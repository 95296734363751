const GetFacetToRemove = (productResultPageData) => {
  try {
    if (
      !productResultPageData.selectedFacetOptions.length ||
      (productResultPageData.isSearchPage &&
        productResultPageData.selectedFacetOptions.length <= 1)
    ) {
      return null
    }

    let filterToRemove = productResultPageData.selectedFacetOptions.find(
      (x) => x.displayText == 'Fitment Filter',
    )
    filterToRemove ??= productResultPageData.selectedFacetOptions.find(
      (x) => x.displayText == 'Fitment',
    )
    filterToRemove ??=
      productResultPageData.selectedFacetOptions[
        productResultPageData.selectedFacetOptions.length - 1
      ]

    return {
      name: filterToRemove.searchResponseFacetOptions[0].displayValue,
      url: filterToRemove.searchResponseFacetOptions[0].facetOptionLink.url,
      noFollow:
        filterToRemove.searchResponseFacetOptions[0].facetOptionLink.noFollow,
      fitmentData: filterToRemove.searchResponseFacetOptions.fitmentDetails,
    }
  } catch (err) {
    console.error('error getting suggested facet to remove', err)
    return null
  }
}

export default GetFacetToRemove
