import React, { useState } from 'react'

import Popover from 'Clutch/Components/Popover'

import Area from 'Clutch/Molecules/Area'

import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'

import styles from './excludedProducts.module.scss'

const ExcludedProducts = ({ filteringRule }) => {
  const [isOpen, setIsOpen] = useState(false)

  const excludedProducts =
    filteringRule?.conditions?.filter((condition) => condition.isNegated) || []
  const hasExcludedProducts = excludedProducts.length > 0

  const handleMouseEnter = () => {
    if (hasExcludedProducts) {
      setIsOpen(true)
    }
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  const getDisplayKey = (key) => {
    switch (key) {
      case 'product_id':
        return 'SKU Variant'
      case 'product_group_id':
        return 'SKU Base'
      default:
        return key
    }
  }

  const renderExcludedProducts = () => {
    return (
      <Area intent="warning" className={styles.excludedGroup}>
        <Typography size={1} className={styles.excludedGroupTitle}>
          <strong>Excluded Products</strong>
        </Typography>
        <ul className={styles.excludedList}>
          {excludedProducts.map((condition, index) => (
            <li key={index} className={styles.excludedItem}>
              <div className={styles.excludedInfo}>
                <Typography size={0.875} className={styles.excludedKey}>
                  {getDisplayKey(condition.key)}:
                </Typography>
                <Typography size={0.875} className={styles.excludedValue}>
                  {condition.value}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </Area>
    )
  }

  const areaContent = (
    <Area
      className={styles.rule}
      title="Excluded Products"
      intent={hasExcludedProducts ? 'warning' : undefined}
    >
      <Typography size={0.75}>
        {hasExcludedProducts
          ? `${excludedProducts.length} product(s) excluded`
          : 'No products excluded'}
      </Typography>
    </Area>
  )

  return (
    <Card noMargin noPadding className={styles.card}>
      <div
        className={styles.excludedWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {hasExcludedProducts ? (
          <Popover
            content={
              <div className={styles.popoverContent}>
                {renderExcludedProducts()}
              </div>
            }
            isOpen={isOpen}
          >
            {areaContent}
          </Popover>
        ) : (
          areaContent
        )}
      </div>
    </Card>
  )
}

export default ExcludedProducts
