import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import useProductResultPage from '../../hooks/useProductResultPage'
import styles from './title.module.scss'

const Title = () => {
  const pageContent = useProductResultPage((x) => x.pageContent)
  const displayTitle = useProductResultPage((x) => x.displayTitle)
  const searchSpelling = useProductResultPage((x) => x.searchSpelling)

  return (
    <>
      <>
        {!pageContent?.bannerImageUrl && !pageContent?.isMakerPage ? (
          <>
            <Typography
              font={'bold'}
              tone={'contrast'}
              size={2}
              as={'h1'}
              dataTesting={`title_${displayTitle}`}
            >
              {displayTitle}
            </Typography>

            {searchSpelling?.explaination ? (
              <Typography className={styles.explaination}>
                {searchSpelling?.explaination}
              </Typography>
            ) : null}
            <br />
          </>
        ) : null}
      </>
    </>
  )
}

export default Title
