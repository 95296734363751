import { useContext, useEffect } from 'react'

import { useUserStore } from 'Clutch/Stores/UserStore/UserStore'

import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import useSegment from 'Clutch/Hooks/useSegment'

import ProductCard from 'Components/ProductCard/ProductCard'

import Carousel from 'Clutch/Organisms/Carousel'

import useProductResultPage from '../../hooks/useProductResultPage'
import styles from './productCarousel.module.scss'

const ProductCarousel = ({ numProducts = 6 }) => {
  const displayTitle = useProductResultPage((x) => x.displayTitle)
  const searchProducts = useProductResultPage((x) => x.searchProducts)

  const userVin = useUserStore((x) => x.context.userVin)
  const userGuid = useUserStore((x) => x.context.userGuid)
  const { sendCustomSegmentTrackEvent } = useSegment()
  const products = searchProducts
  if (
    !products?.length ||
    products.length < numProducts ||
    !products.slice(0, numProducts).every((x) => x.skuBaseDetails != null)
  )
    return false

  const productsToShow = products.slice(0, numProducts)

  const cleanedPageTitle = displayTitle.replace(/ /g, '_')
  const list_id = `FRP_Products_${cleanedPageTitle}`
  const productListViewedSegmentEvent = {
    name: 'Product List Viewed',
    properties: {
      user_vin: `${userVin()}`,
      web_user_guid: `${userGuid}`,
      list_id: list_id,
      category: cleanedPageTitle,
      products: (productsToShow || []).map((sku, i) => {
        const skuBaseDetails = sku.skuBaseDetails
        return {
          product_id: skuBaseDetails.skuBaseNumber,
          sku: skuBaseDetails.skuBaseNumber,
          name: skuBaseDetails.displayTitle,
          price: skuBaseDetails.lowPrice,
          position: i + 1,
          category: skuBaseDetails.segmentCategorization,
          url: skuBaseDetails.productPageUrl,
          image_url: skuBaseDetails.imageLink,
          list_id: list_id,
          quantity: 1,
          brand: skuBaseDetails.brand,
          availability: skuBaseDetails?.availability,
          rating: skuBaseDetails?.reviewCount ? skuBaseDetails?.rating : 0,
          reviews: skuBaseDetails?.reviewCount,
          audience_affinity: getSuperMarketAffinity(skuBaseDetails),
        }
      }),
    },
  }

  useEffect(() => {
    sendCustomSegmentTrackEvent(productListViewedSegmentEvent)
  }, [])

  return (
    <Carousel className={styles.productCarousel}>
      {productsToShow.map((product, i) => {
        const skuBaseDetails = product.skuBaseDetails
        const segmentEvent = {
          event: 'Product Clicked',
          properties: {
            sku: skuBaseDetails.skuBaseNumber,
            product_id: skuBaseDetails.skuBaseNumber,
            name: skuBaseDetails.displayTitle,
            url: skuBaseDetails.productPageUrl,
            image_url: skuBaseDetails.imageLink,
            price: skuBaseDetails.lowPrice,
            position: i,
            brand: skuBaseDetails.brand,
            list_id: list_id,
            availability: skuBaseDetails.availability,
            rating: skuBaseDetails.reviewCount ? skuBaseDetails.rating : 0,
            reviews: skuBaseDetails.reviewCount,
            audience_affinity: getSuperMarketAffinity(skuBaseDetails),
          },
        }

        return (
          <div
            className={styles.productCard}
            key={`${product.skuBaseDetails.skuBaseNumber}`}
          >
            <ProductCard
              productPageUrl={product.skuBaseDetails.productPageUrl}
              imageUrl={product.skuBaseDetails.imageLink}
              displayTitle={product.skuBaseDetails.displayTitle}
              rating={product.skuBaseDetails.rating}
              reviewCount={product.skuBaseDetails.reviewCount}
              salePriceString={product.skuBaseDetails.pricing?.salePriceString}
              strikeThroughPriceString={
                product.skuBaseDetails.pricing?.strikeThroughPriceString
              }
              percentOffString={
                product.skuBaseDetails.pricing?.percentOffString
              }
              skuBaseNumber={product.skuBaseDetails.skuBaseNumber}
              segmentEvent={segmentEvent}
            />
          </div>
        )
      })}
    </Carousel>
  )
}

export default ProductCarousel
