import Divider from 'Clutch/Atoms/Divider'

import styles from '../sidebar.module.scss'

const SideBarBlockWrapper = ({
  children,
  dividerTop,
  dividerBottom,
  styling,
}) => {
  if (!children) return false

  return (
    <>
      {dividerTop ? <Divider className={styles.divider} /> : null}

      <div className={styling ? styling : styles.container}>{children}</div>
      {dividerBottom ? <Divider className={styles.divider} /> : null}
    </>
  )
}

export default SideBarBlockWrapper
