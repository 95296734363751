import DefaultPageSize from '/constants/pageSize'

const getNumProductsToBeLoaded = (totalProductCount, currentPage) => {
  const shownResultsCount = currentPage * DefaultPageSize

  const loadMoreCount =
    shownResultsCount + DefaultPageSize > totalProductCount
      ? totalProductCount - shownResultsCount
      : DefaultPageSize

  return loadMoreCount
}

export { getNumProductsToBeLoaded }
