import React, { useEffect, useRef } from 'react'

import Breadcrumb from 'Clutch/Molecules/Breadcrumb/Breadcrumb'

import buildBreadCrumb from '../../Utilities/buildBreadCrumb'
import useProductResultPage from '../../hooks/useProductResultPage'

const BreadcrumbWrapper = () => {
  const renderCounter = useRef(0)
  renderCounter.current = renderCounter.current + 1
  console.debug(`Breadcrumb Component Renders: ${renderCounter.current}`)

  const pageContent = useProductResultPage((x) => x.pageContent)
  const breadcrumb = useProductResultPage((x) => x.breadcrumb)
  if (pageContent?.bannerImageUrl && !pageContent?.isMakerPage) {
    return false
  }
  if (pageContent?.isMakerPage) {
    return false
  }

  const structuredBreadcrumb = buildBreadCrumb(breadcrumb)

  //don't show the bc if it's just "home", the <br/> is for spacing
  if ((structuredBreadcrumb || []).length < 2) {
    if (pageContent?.isMakerPage) {
      return false
    }
    return <br />
  }

  return (
    <nav aria-label={'breadcrumb'}>
      <Breadcrumb breadcrumb={structuredBreadcrumb} prp_layout={true} />
    </nav>
  )
}

export default BreadcrumbWrapper
