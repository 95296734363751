import classNames from 'classnames'

import Skeleton from 'Clutch/Atoms/Skeleton'

import styles from './stickyHeader.module.scss'

const StickyHeaderSkeleton = () => {
  const classes = classNames(styles.sticky_header, styles.narrow)
  return (
    <span className={classes}>
      <Skeleton fullWidth height={50} />
    </span>
  )
}

export default StickyHeaderSkeleton
