import React, { useState } from 'react'

import ClassNames from 'classnames'

import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Form from 'Clutch/Atoms/Form'
import Typography from 'Clutch/Atoms/Typography'

import { usePrpStore } from '../../../../contexts/prpContext'
import useProductResultPage from '../../../../hooks/useProductResultPage'
import SideBarBlockWrapper from '../../shared/sideBarBlockWrapper'
import styles from '../../sidebar.module.scss'

const FacetOptionSwitch = ({
  facetName,
  facetOptionValueId,
  brand,
  intent,
  children,
  displayNameOverride = null,
  dividerTop = false,
  dividerBottom = false,
  typographyProps,
  onClick,
  styling,
  textSize,
}) => {
  const thisFacet = useProductResultPage((x) =>
    x.searchResponseFacets.find((x) => x.facetName === facetName),
  )

  const [loading, setLoading] = useState(false)

  const Navigate = usePrpStore((x) => x.Navigate)

  const onClickFacet = (e) => {
    if (onClick) {
      onClick()
    }
    Navigate(e, thisFacetOption.facetOptionLink.url, setLoading)
  }

  if (!thisFacet) {
    return false
  }

  const thisFacetOption = thisFacet.searchResponseFacetOptions.find(
    (x) => x.id === facetOptionValueId,
  )

  if (!thisFacetOption) {
    return false
  }

  return (
    <>
      <SideBarBlockWrapper
        styling={styling}
        dividerBottom={dividerBottom}
        dividerTop={dividerTop}
      >
        <CoveringSpinner isLoading={loading} opacityWhenLoading={0.5}>
          <div
            className={styles.container}
            onClick={(e) => onClickFacet(e)}
            data-testid={`facet-switch-${displayNameOverride ?? thisFacetOption.displayValue}-${facetOptionValueId}`}
          >
            <Form.Switch
              checked={thisFacetOption.isSelected}
              brand={brand}
              intent={intent}
              onChange={() => {}}
            />
            <Typography
              size={textSize ?? 1}
              className={styles.switchLabel}
              {...typographyProps}
            >
              {displayNameOverride ?? thisFacetOption.displayValue}
            </Typography>
          </div>
          {children}
        </CoveringSpinner>
      </SideBarBlockWrapper>
    </>
  )
}

export default FacetOptionSwitch
