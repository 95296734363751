import Skeleton from 'Clutch/Atoms/Skeleton'

import styles from './selectedFacets.module.scss'

const SelectedFacetsSkeleton = () => {
  return (
    <>
      <div className={styles.chips}>
        <div className={styles.fadeout}>
          <Skeleton width={200} height={36} />
        </div>
      </div>
    </>
  )
}

export default SelectedFacetsSkeleton
