import useProductResultPage from '../../hooks/useProductResultPage'
import Campaign from './campaign'

const TopCampaign = () => {
  const campaign = useProductResultPage((x) => x.campaign)
  let shouldHaveTopCompaign =
    campaign?.campaignPositions?.campaignPositions?.find((x) => x < 0)
  if (campaign?.campaignType === 'BannerCampaign') {
    if (campaign?.assets?.find((x) => x.placement === 'top')) {
      shouldHaveTopCompaign = true
    }
  }

  return (campaign?.htmlText || campaign?.assets) && shouldHaveTopCompaign ? (
    <Campaign placement={'top'} />
  ) : (
    false
  )
}

export default TopCampaign
